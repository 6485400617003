@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap");
/* rsc */
/* constant */
:root {
  --size-5: calc(5 / 750 * 100vw);
  --size-10: calc(10 / 750 * 100vw);
  --size-15: calc(15 / 750 * 100vw);
  --size-20: calc(20 / 750 * 100vw);
  --size-25: calc(25 / 750 * 100vw);
  --size-30: calc(30 / 750 * 100vw);
  --size-35: calc(35 / 750 * 100vw);
  --size-40: calc(40 / 750 * 100vw);
  --size-45: calc(45 / 750 * 100vw);
  --size-50: calc(50 / 750 * 100vw);
  --size-55: calc(55 / 750 * 100vw);
  --size-60: calc(60 / 750 * 100vw);
  --size-65: calc(65 / 750 * 100vw);
  --size-70: calc(70 / 750 * 100vw);
  --size-75: calc(75 / 750 * 100vw);
  --size-80: calc(80 / 750 * 100vw);
  --size-85: calc(85 / 750 * 100vw);
  --size-90: calc(90 / 750 * 100vw);
  --size-95: calc(95 / 750 * 100vw);
  --size-100: calc(100 / 750 * 100vw);
  --size-105: calc(105 / 750 * 100vw);
  --size-110: calc(110 / 750 * 100vw);
  --size-115: calc(115 / 750 * 100vw);
  --size-120: calc(120 / 750 * 100vw);
  --size-125: calc(125 / 750 * 100vw);
  --size-130: calc(130 / 750 * 100vw);
  --size-135: calc(135 / 750 * 100vw);
  --size-140: calc(140 / 750 * 100vw);
  --size-145: calc(145 / 750 * 100vw);
  --size-150: calc(150 / 750 * 100vw);
  --size-155: calc(155 / 750 * 100vw);
  --size-160: calc(160 / 750 * 100vw);
  --size-165: calc(165 / 750 * 100vw);
  --size-170: calc(170 / 750 * 100vw);
  --size-175: calc(175 / 750 * 100vw);
  --size-180: calc(180 / 750 * 100vw);
  --size-185: calc(185 / 750 * 100vw);
  --size-190: calc(190 / 750 * 100vw);
  --size-195: calc(195 / 750 * 100vw);
  --size-200: calc(200 / 750 * 100vw);
  --size-205: calc(205 / 750 * 100vw);
  --size-210: calc(210 / 750 * 100vw);
  --size-215: calc(215 / 750 * 100vw);
  --size-220: calc(220 / 750 * 100vw);
  --size-225: calc(225 / 750 * 100vw);
  --size-230: calc(230 / 750 * 100vw);
  --size-235: calc(235 / 750 * 100vw);
  --size-240: calc(240 / 750 * 100vw);
  --size-245: calc(245 / 750 * 100vw);
  --size-250: calc(250 / 750 * 100vw);
  --size-255: calc(255 / 750 * 100vw);
  --size-260: calc(260 / 750 * 100vw);
  --size-265: calc(265 / 750 * 100vw);
  --size-270: calc(270 / 750 * 100vw);
  --size-275: calc(275 / 750 * 100vw);
  --size-280: calc(280 / 750 * 100vw);
  --size-285: calc(285 / 750 * 100vw);
  --size-290: calc(290 / 750 * 100vw);
  --size-295: calc(295 / 750 * 100vw);
  --size-300: calc(300 / 750 * 100vw);
  --size-305: calc(305 / 750 * 100vw);
  --size-310: calc(310 / 750 * 100vw);
  --size-315: calc(315 / 750 * 100vw);
  --size-320: calc(320 / 750 * 100vw);
  --size-325: calc(325 / 750 * 100vw);
  --size-330: calc(330 / 750 * 100vw);
  --size-335: calc(335 / 750 * 100vw);
  --size-340: calc(340 / 750 * 100vw);
  --size-345: calc(345 / 750 * 100vw);
  --size-350: calc(350 / 750 * 100vw);
  --size-355: calc(355 / 750 * 100vw);
  --size-360: calc(360 / 750 * 100vw);
  --size-365: calc(365 / 750 * 100vw);
  --size-370: calc(370 / 750 * 100vw);
  --size-375: calc(375 / 750 * 100vw);
  --size-380: calc(380 / 750 * 100vw);
  --size-385: calc(385 / 750 * 100vw);
  --size-390: calc(390 / 750 * 100vw);
  --size-395: calc(395 / 750 * 100vw);
  --size-400: calc(400 / 750 * 100vw);
  --size-405: calc(405 / 750 * 100vw);
  --size-410: calc(410 / 750 * 100vw);
  --size-415: calc(415 / 750 * 100vw);
  --size-420: calc(420 / 750 * 100vw);
  --size-425: calc(425 / 750 * 100vw);
  --size-430: calc(430 / 750 * 100vw);
  --size-435: calc(435 / 750 * 100vw);
  --size-440: calc(440 / 750 * 100vw);
  --size-445: calc(445 / 750 * 100vw);
  --size-450: calc(450 / 750 * 100vw);
  --size-455: calc(455 / 750 * 100vw);
  --size-460: calc(460 / 750 * 100vw);
  --size-465: calc(465 / 750 * 100vw);
  --size-470: calc(470 / 750 * 100vw);
  --size-475: calc(475 / 750 * 100vw);
  --size-480: calc(480 / 750 * 100vw);
  --size-485: calc(485 / 750 * 100vw);
  --size-490: calc(490 / 750 * 100vw);
  --size-495: calc(495 / 750 * 100vw);
  --size-500: calc(500 / 750 * 100vw);
}

@media screen and (min-width: 1006px) {
  :root {
    --size-5: 5px;
    --size-10: 10px;
    --size-15: 15px;
    --size-20: 20px;
    --size-25: 25px;
    --size-30: 30px;
    --size-35: 35px;
    --size-40: 40px;
    --size-45: 45px;
    --size-50: 50px;
    --size-55: 55px;
    --size-60: 60px;
    --size-65: 65px;
    --size-70: 70px;
    --size-75: 75px;
    --size-80: 80px;
    --size-85: 85px;
    --size-90: 90px;
    --size-95: 95px;
    --size-100: 100px;
    --size-105: 105px;
    --size-110: 110px;
    --size-115: 115px;
    --size-120: 120px;
    --size-125: 125px;
    --size-130: 130px;
    --size-135: 135px;
    --size-140: 140px;
    --size-145: 145px;
    --size-150: 150px;
    --size-155: 155px;
    --size-160: 160px;
    --size-165: 165px;
    --size-170: 170px;
    --size-175: 175px;
    --size-180: 180px;
    --size-185: 185px;
    --size-190: 190px;
    --size-195: 195px;
    --size-200: 200px;
    --size-205: 205px;
    --size-210: 210px;
    --size-215: 215px;
    --size-220: 220px;
    --size-225: 225px;
    --size-230: 230px;
    --size-235: 235px;
    --size-240: 240px;
    --size-245: 245px;
    --size-250: 250px;
    --size-255: 255px;
    --size-260: 260px;
    --size-265: 265px;
    --size-270: 270px;
    --size-275: 275px;
    --size-280: 280px;
    --size-285: 285px;
    --size-290: 290px;
    --size-295: 295px;
    --size-300: 300px;
    --size-305: 305px;
    --size-310: 310px;
    --size-315: 315px;
    --size-320: 320px;
    --size-325: 325px;
    --size-330: 330px;
    --size-335: 335px;
    --size-340: 340px;
    --size-345: 345px;
    --size-350: 350px;
    --size-355: 355px;
    --size-360: 360px;
    --size-365: 365px;
    --size-370: 370px;
    --size-375: 375px;
    --size-380: 380px;
    --size-385: 385px;
    --size-390: 390px;
    --size-395: 395px;
    --size-400: 400px;
    --size-405: 405px;
    --size-410: 410px;
    --size-415: 415px;
    --size-420: 420px;
    --size-425: 425px;
    --size-430: 430px;
    --size-435: 435px;
    --size-440: 440px;
    --size-445: 445px;
    --size-450: 450px;
    --size-455: 455px;
    --size-460: 460px;
    --size-465: 465px;
    --size-470: 470px;
    --size-475: 475px;
    --size-480: 480px;
    --size-485: 485px;
    --size-490: 490px;
    --size-495: 495px;
    --size-500: 500px;
  }
}
/*====================================================================
 HEADER CSS
====================================================================*/
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #FFF;
}

.header__inner {
  height: var(--size-80);
  padding: 0 var(--size-30);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .header__inner {
    height: var(--size-100);
  }
}
.header .c_logo {
  width: var(--size-200);
}

@media screen and (max-width: 768px) {
  .header .c_logo {
    width: 41.3333333333vw;
  }
}
.header .c_global_navi {
  display: flex;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 600;
  letter-spacing: 0.05rem;
}

@media screen and (max-width: 768px) {
  .header .c_global_navi {
    display: none;
    position: absolute;
    width: 100%;
    z-index: 9;
    background: #FFF;
    top: var(--size-100);
    left: 0;
    height: 100vh;
    padding-top: var(--size-60);
  }
}
.header .c_global_navi .item a, .header .c_global_navi .item span {
  height: 80px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header .c_global_navi .item a, .header .c_global_navi .item span {
    height: var(--size-120);
    font-size: 4.2666666667vw;
  }
}
.header .c_global_navi .item a:hover, .header .c_global_navi .item span:hover {
  opacity: 1;
}

.header .c_global_navi .item a:hover:before, .header .c_global_navi .item span:hover:before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: #B2B2B2;
  position: absolute;
  left: 0;
  bottom: 1px;
}

@media screen and (max-width: 768px) {
  .header .c_global_navi .item a:hover:before, .header .c_global_navi .item span:hover:before {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .header .c_global_navi .item a, .header .c_global_navi .item span {
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .header .c_global_navi .item span {
    height: var(--size-70);
    align-items: flex-end;
    margin-bottom: var(--size-20);
  }
}
.header .c_global_navi .item span.is-active:before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: #B2B2B2;
  position: absolute;
  left: 0;
  bottom: 1px;
}

@media screen and (max-width: 768px) {
  .header .c_global_navi .item span.is-active:before {
    display: none;
  }
}
.header .c_global_navi .item .c_sub_global_navi a {
  height: 42px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .header .c_global_navi .item .c_sub_global_navi a {
    height: var(--size-70);
  }
}
.header .c_global_navi .item + .item {
  margin-left: var(--size-40);
}

@media screen and (max-width: 768px) {
  .header .c_global_navi .item + .item {
    margin-left: 0;
  }
}
.header .c_global_navi .c_global_link {
  display: flex;
  align-items: center;
}

.header .c_global_navi .c_global_link:after {
  content: "";
  display: block;
  background-image: url("../../img/cmn/icon_blank.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 11px;
  height: 10px;
  margin-left: var(--size-5);
}

.l_sub_global_navi {
  position: absolute;
  z-index: 999;
  width: 100%;
  background: #F0F0F0;
  display: none;
  left: 0;
  top: 80px;
}

@media screen and (max-width: 768px) {
  .l_sub_global_navi {
    display: block;
    position: initial;
    background: none;
  }
}
.l_sub_global_navi .c_sub_global_navi {
  padding: var(--size-20) 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .l_sub_global_navi .c_sub_global_navi {
    display: block;
    padding: 0;
  }
}
.l_sub_global_navi .c_sub_global_navi li a {
  color: #B2B2B2;
  padding: var(--size-10) var(--size-40);
  display: block;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .l_sub_global_navi .c_sub_global_navi li a {
    border: none;
    font-size: 4.2666666667vw;
    padding: 0;
  }
}
.l_sub_global_navi .c_sub_global_navi li + li {
  margin-left: var(--size-40);
}

@media screen and (max-width: 768px) {
  .l_sub_global_navi .c_sub_global_navi li + li {
    margin-left: 0;
  }
}
/*====================================================================
 HEADER HAMBURGER CSS
====================================================================*/
.c_header-hamburger-btn {
  display: none;
}

@media screen and (max-width: 768px) {
  .c_header-hamburger-btn {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .c_header-hamburger-btn {
    position: absolute;
    height: var(--size-100);
    width: var(--size-100);
    top: 0;
    right: 0;
  }
}
@media screen and (max-width: 768px) and (min-width: 1006px) {
  .c_header-hamburger-btn {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .c_header-hamburger-btn .c_hh_btn {
    position: absolute;
    top: var(--size-25);
    left: 0;
  }
  .c_header-hamburger-btn .c_hh_btn span {
    background: #0B0B0B;
    width: var(--size-60);
    transition: 0.3s;
    height: 0.8vw;
    display: block;
  }
  .c_header-hamburger-btn .c_hh_btn span:first-child {
    position: absolute;
    top: 0;
  }
  .c_header-hamburger-btn .c_hh_btn span:nth-child(2) {
    position: absolute;
    top: 2.8vw;
  }
  .c_header-hamburger-btn .c_hh_btn span:nth-child(3) {
    position: absolute;
    top: 5.6vw;
  }
}
@media screen and (max-width: 768px) {
  .c_header-hamburger-btn.is-active .c_hh_btn span {
    transition: 0.3s;
    position: absolute;
  }
  .c_header-hamburger-btn.is-active .c_hh_btn span:first-child {
    transform: rotate(45deg);
    top: 3.2vw;
  }
  .c_header-hamburger-btn.is-active .c_hh_btn span:nth-child(2) {
    display: none;
  }
  .c_header-hamburger-btn.is-active .c_hh_btn span:nth-child(3) {
    transform: rotate(-45deg);
    top: 3.2vw;
  }
}
/*====================================================================
COMPLETE CSS
====================================================================*/
.c_ul_title_wrap {
  padding-top: var(--size-70);
}

@media screen and (max-width: 768px) {
  .c_ul_title_wrap {
    padding-top: 0;
  }
}
.notfound {
  padding-top: var(--size-80);
  padding-bottom: var(--size-120);
  text-align: center;
}

@media screen and (max-width: 768px) {
  .notfound {
    padding-top: var(--size-100);
    padding-bottom: var(--size-160);
  }
}
.notfound .notfound_text_wrap .c_title {
  font-size: 2.8rem;
  margin-bottom: var(--size-40);
}

@media screen and (max-width: 768px) {
  .notfound .notfound_text_wrap .c_title {
    font-size: 6.4vw;
  }
}
@media screen and (max-width: 768px) {
  .notfound .notfound_text_wrap .c_text {
    text-align: left;
  }
}
.notfound .c_btn {
  margin-top: var(--size-50);
}

@media screen and (max-width: 768px) {
  .notfound .c_btn {
    margin-top: var(--size-100);
  }
}
.notfound .c_service_wrap {
  margin-top: var(--size-90);
}

@media screen and (max-width: 768px) {
  .notfound .c_service_wrap {
    margin-top: var(--size-160);
  }
}
.notfound .c_service_wrap .c_title {
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "Noto Sans JP", sans-serif;
  margin-bottom: var(--size-30);
}

.notfound .c_service_wrap .c_text {
  text-align: left;
}

.notfound .l_c_service_list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0 24px;
}

@media screen and (max-width: 768px) {
  .notfound .l_c_service_list {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--size-30);
  }
}
.notfound .l_c_service_list .c_list .c_text {
  margin-top: var(--size-10);
  font-size: 1.2rem;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .notfound .l_c_service_list .c_list .c_text {
    font-size: 2.6666666667vw;
  }
}
.notfound .c_btn a {
  width: 252px;
}

@media screen and (max-width: 768px) {
  .notfound .c_btn a {
    width: 67.2vw;
  }
}