@charset "UTF-8";
@import '../_mixin';
@import '../_config';
@import '../inc/_header-default';
/*====================================================================
COMPLETE CSS
====================================================================*/
.c_ul_title_wrap {
  padding-top: var(--size-70);
  @include sp {
    padding-top: 0;
  }
}
.notfound{
  padding-top: var(--size-80);
  padding-bottom: var(--size-120);
  text-align: center;
  @include sp {
    padding-top: var(--size-100);
    padding-bottom: var(--size-160);
  }
  .notfound_text_wrap {
    .c_title {
      font-size: 2.8rem;
      margin-bottom: var(--size-40);
      @include sp {
        font-size: get_vw(48);
      }
    }
    .c_text {
      @include sp {
        text-align: left;
      }
    }
  }
  
  .c_btn {
    margin-top: var(--size-50);
    @include sp {
      margin-top: var(--size-100);
    }
  }
  
  .c_service_wrap {
    margin-top: var(--size-90);
    @include sp {
      margin-top: var(--size-160);
    }
    .c_title {
      font-size: 1.8rem;
      @include font-weight(bold);
      font-family: $noto_sans;
      margin-bottom: var(--size-30);
    }
    .c_text {
      text-align: left;
    }
  }
  
  .l_c_service_list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap :0 24px;
    @include sp {
      grid-template-columns: repeat(2, 1fr);
      gap :var(--size-30);
    }
    .c_list {
      .c_text {
        margin-top: var(--size-10);
        font-size: 1.2rem;
        text-align: center;
        @include sp {
          font-size: get_vw(20);
        }
      }
    }
  }
  
  .c_btn a {
    width: 252px;
    @include sp {
      @include p-vw(width, 504);
    }
    
  }
}


